
.home__banner
    width: 100%

.home__banner__img
    width: 100%

.service__content
    width: 100%
    background: $bg-grey
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

.home__service__img
    width: 90%
    margin-bottom: 20px
    box-shadow: $box-shadow
    @include boxShadow (6px, $bg-bluegrey)

.service__info
    display: flex
    flex-direction: column
    align-items: center
    &__title
        font-family: $font-Cairo
        font-size: $Size-M
        margin-bottom: 10px
        text-align: center
    &__description
        margin-bottom: 15px
        text-align: justify
        font-weight: 400
        font-size: $Size-M
        padding: 10px

.contact
    padding: 15px
    margin: 20px 0px
    background: $bg-white

    &__form
        background: $txt-white
        @include boxShadow (6px,$txt-darkblue)
        padding: 10px 15px
        display: flex
        flex-direction: column
        align-items: center
        width: 100%

    &__title
        font-family: $font-Cairo
        font-size: $Size-L
        color: $txt-darkgrey

    &__input__group
        padding: 15px 0px
        display: flex
        flex-direction: column
        width: 100%
    &__input__label
        font-size: $Size-S
        font-weight: 600
        color: $txt-black
        margin-bottom: 8px
        margin-right: 8px
    &__input
        width: 100%
        height: 40px
        padding: 5px
        font-family: $font-Exo
        font-size: $Size-S
        border: none
    &__input__text
        padding: 5px
        height: 150px
        overflow-y: auto
        font-family: $font-Exo
        font-size: $Size-S
        border: none

    &__form__Btn
        width: auto
        font-size: $Size-S
        aling-self: center
        margin: 10px 0px

    &__info
        padding: 10px
        margin: 10px 0px
        display: flex
        flex-direction: column
        &__title
            padding: 30px 15px 0px
            margin-bottom: 8px
            font-family: $font-Cairo
            color: $bg-darkgrey

        &__label
            font-size: $Size-M
            font-family: $font-Cairo
            color: $txt-darkgrey
            display: flex
            align-items: center

        &__icon
            color: $bg-greenmint
            font-size: $Size-L
            margin-right: 8px
            filter: drop-shadow(1px 1px 2px $bg-darkgrey)

@media screen and (min-width: 728px)
    .contact
        display: grid
        grid-template-columns: 1fr 1fr
        column-gap: 20px
        &__info
            flex-direction: column
            // justify-content: center
            align-items: center

    .contact__info__label
        align-self: flex-start
        margin-bottom: 10px
        margin-left: 20px
        &:hover
            .contact__info__icon
                transform: translateY(-15%)
                transition: all $speed

@media screen and (min-width: 1024px)
    .service__info__title
        font-size: $Size-XL

    .home__service__img
        width: 80%
        max-width: 700px

    .contact__info__title
    .contact__info__label
        font-size: $Size-L
    .contact__info__icon
        font-size: $Size-XL
