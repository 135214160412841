

.flujo
    background: $txt-white
    padding: 15px
    box-shadow: $box-shadow
    overflow-y: scroll
    height: 520px
    max-height: 550px
        
    &__label
        width: 80%
        font-size: $Size-S
        font-weight: 600
    &__title
        position: relative
        font-family: $font-Cairo
        font-weight: 600
        font-size: $Size-L
        text-align: center
        display: flex
        justify-content: center
        align-items: center
        line-height: 25px
        width: 100%
        background: $bg-greenmint
        padding: 15px
        border-radius: 0px 8px
    &__subtitle
        position: relative
        font-family: $font-Cairo
        font-weight: 600
        font-size: $Size-M
        text-align: center
        display: flex
        justify-content: center
        align-items: center
        line-height: 10px
        width: max-content
        padding: 15px
        border: 2px solid $bg-greenmint
        border-radius: 0px 8px
        margin: 25px 0px
    &__btn
        width: 100%
        display: flex
        justify-content: center
    &__Icon
        background: $bg-greenmint
        padding: 5px
        width: 35px
        height: 35px
        border-radius: 100%
        color: $txt-white
        margin-right: 12px
        box-shadow: 0px 0px 6px 0px $txt-darkblue
    &__closeIcon
        font-size: $Size-XL
        color: $bg-darkgrey
        z-index: 1000
        position: absolute
        right: 10px
        top: 10px
    &__input-group
        margin: 20px 0px
        display: flex
        align-items: center
        width: 100%
        &__label
            width: 80%
            font-size: $Size-S
            font-weight: 400
            display: flex
            align-items: center
        &__input
            width: 20%
            height: 35px
            padding: 5px
            box-shadow: 0px 0px 2px 0px $txt-black
            border: none
            background: $bg-white
            border-radius: 0px 6px
        &__option
            padding: 5px
            border: none
            border-radius: 0px 6px
            font-family: $font-Exo
            font-size: $Size-S
            font-weight: 600
        &__checkbox
            padding: 5px
            border: none
            border-radius: 0px 6px
            width: 20px
            height: 20px
            margin-left: 20px

/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/

.flujo::-webkit-scrollbar 
    -webkit-appearance: none

.flujo::-webkit-scrollbar:vertical 
    width:5px

.flujo::-webkit-scrollbar-button:increment,.flujo::-webkit-scrollbar-button 
    display: none

.flujo::-webkit-scrollbar:horizontal 
    height: 10px

.flujo::-webkit-scrollbar-thumb 
    background-color: $bg-greengrass
    border-radius: 20px
    border: 2px solid $bg-greenmint

.flujo::-webkit-scrollbar-track 
    border-radius: 10px  


@media screen and (min-width: 728px)
    .flujo
        width: 600px
        margin-top: 50px
        height: max-content
