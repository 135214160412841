
.modalCuadroT
    display: flex
    justify-content: center
    width: 100%
    height: 100%
    position: fixed
    top: 100px
    left: 0px
    z-index: 100
    padding: 5px
    background: #41dd9c18

.restriction
    background: $txt-white
    padding: 5px
    box-shadow: $box-shadow
    overflow-y: scroll
    height: 520px

    &__title
        position: relative
        font-family: $font-Cairo
        font-weight: 600
        font-size: $Size-L
        text-align: center
        display: flex
        justify-content: center
        align-items: center
        line-height: 25px
        width: 100%
        background: $bg-greenmint
        padding: 15px
        border-radius: 0px 8px
    &__title-group
        display: flex
        width: 100%
        grid-column: span 4
    &__subtitle
        position: relative
        font-family: $font-Cairo
        font-weight: 600
        font-size: $Size-M
        text-align: center
        display: flex
        justify-content: center
        align-items: center
        line-height: 20px
        width: 100%
        padding: 15px
        border: 2px solid $bg-greenmint
        border-radius: 0px 8px
        margin-bottom: 15px
        margin-top: 10px
    &__btn
        width: 100%
        display: flex
        justify-content: center
    &__closeIcon
        font-size: $Size-XL
        color: $bg-darkgrey
        z-index: 1000
        position: absolute
        right: 10px
        top: 10px
        cursor: pointer
    &__addIcon
        font-size: $Size-XL
        color: $bg-darkgrey
        z-index: 1000
        cursor: pointer
    &__inputIcon
        font-size: $Size-L
        color: $bg-greenmint
        z-index: 1000
        cursor: pointer
        position: absolute
        top: 5px
        left: 90%
    &__input-box
        width: 50%
        margin-bottom: 20px
        position: relative
    &__input-group
        margin: 20px 0px
        display: flex
        align-items: center
        width: 100%
        &__label
            width: 80%
            font-size: $Size-S
            font-weight: 400
            text-align: justify
        &__input
            width: max-content
            height: 35px
            padding: 5px
            box-shadow: 0px 0px 2px 0px $txt-black
            border: none
            background: $bg-white
            border-radius: 0px 6px
        &__option
            padding: 5px
            border: none
            border-radius: 0px 6px
            font-family: $font-Exo
            font-size: $Size-S
            font-weight: 600
            width: max-content
        &__text
            display: flex
            align-items: center
            font-size: $Size-S
            font-weight: 400
            text-align: end
        &__general
            width: 35px
            padding: 5px
            border: 1px solid $txt-black
            border-radius: 0px 6px
            height: 23px
            margin: 0px 7px
        &__checkbox
            padding: 5px
            border: none
            border-radius: 0px 6px
            width: 20px
            height: 20px
            margin-right: 8px
            
.asignDates
    display: grid
    grid-template-columns: 1fr 2fr
    position: relative
    align-items: flex-end
    justify-content: flex-end
    margin-top: 0

.restrictedDates
    display: grid
    grid-template-columns: 1fr 1fr 1fr 1fr
    position: relative
    margin-top: 0
    width: 100%


.addRestriction
    margin-top: 10px
    width: 100%
    display: flex
    align-self: flex-end
    align-items: center
    justify-self: flex-end
    justify-content: space-between
    border-bottom: 1px solid $bg-greenmint

.colaborador
    height: 35px
    width: 60px
    margin-left: 10px
    padding: 5px
    box-shadow: 0px 0px 2px 0px $txt-black
    border: none
    background: $bg-white
    border-radius: 0px 6px
    font-family: $font-Exo
    font-size: $Size-S
    font-weight: 600
    align-self: center

.select
    margin-left: 10px
    width: 80px

.calendar__group
    justify-content: center
.calendar__group2
    align-items: flex-start
    grid-column: span 2
    justify-content: space-evenly
.calendar__group3
    align-items: flex-start
    justify-content: space-evenly
    grid-column: span 4

.calendar__container,
.calendar
    overflow: hidden
    overflow-x: auto
    box-shadow: $box-shadow
    &:hover
        height: auto !important
    &__label
        margin-right: 15px
        padding: 15px 0px 11px 0px
        align-self: flex-end

.calendar__container
    grid-column: 1

.calendar
    width: 100%

.calendar1
    height: 55px
    overflow: hidden
    box-shadow: $box-shadow
    &:hover
        height: auto
    &__label
        margin-right: 15px
        padding: 15px 0px 11px 0px

.rdrDateDisplayWrapper
    background-color: transparentize($bg-greenmint, 0.5 ) !important
    height: auto

.calendar__CollistA
    display: flex
    flex-direction: column

.calendar__CollistR
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    align-items: center

.Motivo,
.fechasR,
.colab
    display: flex
    align-items: center
    width: 100%
    margin-top: 15px
    font-weight: 600
    font-family: $font-Exo

.Motivo
    margin-left: -30px

.equalTo
    // margin-left: -100px


.msg__error__workflow
    display: flex
    padding: 10px
    text-align: center
    align-items: center
    justify-content: center
    justify-self: center
    align-self: center
    font-weight: 600
    font-size: $Size-S
    color: $A-rederror
    margin-bottom: 15px
    margin-top: -20px
    width: 100%
    height: auto
    background: transparentize($A-rederror, 0.8)

.msg__error__restrict
    display: flex
    padding: 10px
    text-align: center
    align-items: center
    justify-content: center
    justify-self: center
    align-self: center
    font-weight: 600
    font-size: $Size-S
    color: $A-rederror
    margin-bottom: 15px
    margin-top: 10px
    width: 100%
    height: auto
    background: transparentize($A-rederror, 0.8)

/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/

.restriction::-webkit-scrollbar
    -webkit-appearance: none

.restriction::-webkit-scrollbar:vertical
    width: 5px

.restriction::-webkit-scrollbar-button:increment,.restriction::-webkit-scrollbar-button
    display: none

.restriction::-webkit-scrollbar:horizontal
    height: 10px

.restriction::-webkit-scrollbar-thumb
    background-color: $bg-greengrass
    border-radius: 20px
    border: 2px solid $bg-greenmint

.restriction::-webkit-scrollbar-track
    border-radius: 10px

@media screen and (min-width: 728px)
    .restriction
        width: 700px
        margin-top: 50px
        height: max-content
        max-height: 600px
        padding: 15px
    .Motivo
        margin-left: -120px

    .equalTo
        margin-left: -100px



