// @import "./config"
.modalCuadroT
    display: flex
    justify-content: center
    width: 100%
    height: 100%
    position: fixed
    top: 100px
    left: 0px
    z-index: 100
    padding: 15px
    background: #41dd9c18

.features
    background: $txt-white
    padding: 15px
    box-shadow: $box-shadow
    overflow-y: scroll
    height: 520px

    &__error
        border-radius: 0px 8px
        border: 1px solid $A-rederror
        padding: 5px
        // background: $A-rederror

    &__title
        position: relative
        font-family: $font-Cairo
        font-weight: 600
        font-size: $Size-L
        text-align: center
        display: flex
        justify-content: center
        align-items: center
        line-height: 25px
        width: 100%
        background: $bg-greenmint
        padding: 15px
        border-radius: 0px 8px
    &__btn
        width: 100%
        display: flex
        justify-content: center
    &__closeIcon
        font-size: $Size-XL
        color: $bg-darkgrey
        z-index: 1000
        position: absolute
        right: 10px
        top: 10px
        cursor: pointer
    &__addIcon
        font-size: $Size-XL
        color: $bg-darkgrey
        z-index: 1000
        cursor: pointer
    &__input-group
        margin: 20px 0px
        display: flex
        align-items: center
        width: 100%
        &__label
            width: 80%
            font-size: $Size-S
            font-weight: 400
            display: flex
            align-items: center
            

        &__input
            width: 20%
            height: 35px
            padding: 5px
            box-shadow: 0px 0px 2px 0px $txt-black
            border: none
            background: $bg-white
            border-radius: 0px 6px
        &__option
            padding: 5px
            border: none
            border-radius: 0px 6px
            font-family: $font-Exo
            font-size: $Size-S
            font-weight: 600
        &__text
            display: flex
            align-items: center
            font-size: $Size-S
            font-weight: 400
            text-align: end

.diferenciaHoraria
    display: flex
    flex-direction: column
    position: relative

.cargaHoraria
    height: 35px
    width: 60px
    margin-left: 10px
    margin-bottom: 20px
    padding: 5px
    box-shadow: 0px 0px 2px 0px $txt-black
    border: none
    background: $bg-white
    border-radius: 0px 6px
    font-family: $font-Exo
    font-size: $Size-S
    font-weight: 600

.nombreEquipo
    height: 35px
    width: 100%
    margin-top: 10px
    margin-bottom: 20px
    padding: 5px
    box-shadow: 0px 0px 2px 0px $txt-black
    border: none
    background: $bg-white
    border-radius: 0px 6px
    font-family: $font-Exo
    font-size: $Size-S
    font-weight: 600

.Pregunta
    display: flex
    justify-content: space-between
    width: 100%

.endBlock
    border-bottom: 3px solid $bg-greenmint
    padding-bottom: 20px

.addColaborador
    margin-top: 10px
    width: 100%
    display: flex
    align-self: flex-end
    align-items: center
    justify-self: flex-end
    justify-content: space-between
    border-bottom: 1px solid $bg-greenmint

.msg__error__workflow
    display: flex
    padding: 10px
    text-align: center
    align-items: center
    justify-content: center
    justify-self: center
    align-self: center
    font-weight: 600
    font-size: $Size-S
    color: $A-rederror
    margin-bottom: 15px
    margin-top: -20px
    width: 100%
    height: auto
    background: transparentize($A-rederror, 0.8)

/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/
.features::-webkit-scrollbar
    -webkit-appearance: none

.features::-webkit-scrollbar:vertical
    width: 5px

.features::-webkit-scrollbar-button:increment,.features::-webkit-scrollbar-button
    display: none

.features::-webkit-scrollbar:horizontal
    height: 10px

.features::-webkit-scrollbar-thumb
    background-color: $bg-greengrass
    border-radius: 20px
    border: 2px solid $bg-greenmint

.features::-webkit-scrollbar-track
    border-radius: 10px

@media screen and (min-width: 728px)
    .features
        width: 600px
        margin-top: 50px
        height: max-content
        max-height: 600px
