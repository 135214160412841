// @import "./config"

.userTcuadros
    width: 100%
    overflow: hidden
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    &__title
        font-family: $font-Cairo
        font-size: $Size-XL
        color: $txt-darkblue
        width: 100%
        line-height: 30px
        margin-top: 50px
        text-align: center
    &__historic
        display: flex
        flex-wrap: wrap
        justify-content: space-evenly


.cuadroActual
    width: 95%
    max-width: 650px
    display: grid
    justify-content: center
    align-items: center
    padding: 20px
    box-shadow: $box-shadow
    border-radius: 0px 6px
    margin: 25px 0px
    &__subtitle
        font-family: $font-Cairo
        font-weight: 800
    &__imgContainer
        width: 100%
        display: flex
        justify-content: center
    &__img
        width: 100%
        object-fit: cover
        box-shadow: 0px 0px 4px 0px $A-darkgrey
    &__controls
        display: flex
        justify-content: space-evenly
        align-self: center
        justify-self: center
        width: 100%
    &__btn
        width: max-content
        font-family: $font-Exo
        font-size: $Size-S
        
    &__btnEmail
        width: max-content
        background: none
        font-family: $font-Exo
        font-size: $Size-S
        padding: 5px
    &__info
        display: flex
        flex-direction: column
        justify-content: center
        padding: 20px
        &__label
            font-family: $font-Cairo
            font-weight: 600
            font-size: $Size-M
        &__labelDato
            font-family: $font-Exo
            font-weight: 300
            font-size: $Size-M
            margin-left: 10px

@media only screen and (min-width: 1024px)
    .cuadroActual
        grid-template-columns: 1fr 1fr

        &__imgContainer
            grid-column: 1
        &__info
            grid-column: 2
        &__controls
            grid-column: span 2
    
    .historic
        max-width: 400px
        &__imgContainer
            grid-column: span 2
            height: 150px
        &__info
            grid-column: span 2
        &__label
            font-size: $Size-S

            