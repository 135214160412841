.welcome
    background: linear-gradient(35deg,$bg-greengrass,$txt-white 20%, $txt-lightgrey, $txt-middlelightgrey, $bg-greenmint )
    position: fixed
    top: 150px
    left: 10px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 20px
    box-shadow: $box-shadow
    width: 95%

    &__img
        width: 40%
        object-fit: cover
        border-radius: 8px 0px
        box-shadow: $box-shadow

    &__title
        font-family: $font-Cairo
        font-size: $Size-M

    &__text
        font-family: $font-Exo
        font-size: $Size-S
        font-weight: 400
        margin-top: 15px
        text-align: center

    &__controls
        margin-top: 25px
        margin-bottom: 20px
        display: grid
        grid-template-columns: 1fr 1fr
        grid-gap: 15px

    &__btn1
        margin-right: 5px
        box-shadow: none
        font-size: $Size-S
        text-align: center
        height: auto
        display: flex
        align-items: center
        justify-content: center

        &:hover
            @include btnStyle($bg: $bg-greenmint, $box_shadow: $box-shadow, $font: $Size-S, $height: auto)
            color: $txt-black
            text-shadow: 0px 1px 3px $txt-darkgrey

    &__btn2
        margin-left: 5px
        font-size: $Size-S
        text-align: center
        height: 60px
        display: flex
        align-items: center
        justify-content: center
        &:hover
            @include btnStyle($bg: $bg-greenmint, $box_shadow: $box-shadow, $font: $Size-S, $height: 60px)
            color: $txt-black
            text-shadow: 0px 1px 3px $txt-darkgrey

.input__checkbox
    width: 100%
    grid-column: span 2
    display: flex
    align-items: center
    margin-top: 20px

.checkbox__input
    margin-right: 10px
    height: 20px
    width: 20px
.checkbox__label
    font-weight: 400
    font-family: $font-Exo

@media screen and (min-width: 728px)

@media screen and (min-width: 1024px)
    .welcome
        width: auto
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

        &__img
            width: 10%
            object-fit: cover
            border-radius: 8px 0px
            box-shadow: $box-shadow

        &__title
            font-family: $font-Cairo
            font-size: $Size-L

        &__text
            font-family: $font-Exo
            font-size: $Size-M
            font-weight: 400
            margin-top: 15px

        &__controls
            margin-top: 25px
            margin-bottom: 20px
            display: grid
            grid-template-columns: 1fr 1fr
            grid-gap: 15px
