.modalIngreso
    animation: Modal-swipeLeft $speed ease-in
    position: absolute
    top: 100px
    right: 0px
    width: 100%
    height: 80vh
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    transition: all $speed ease
    z-index: 1000
    background-color: $txt-middlelightgrey
    padding: 20px
    box-shadow: $box-shadow

.modalIngreso__form
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    justify-content: space-evenly

.form__input-box
    width: 100%
    margin-bottom: 1.25rem

.form__label
    display: flex
    width: 100%
    margin-bottom: 0.625rem
    color: $bg-greenmint
    font-size: $Size-M
    font-weight: 800

.form__icon
    font-size: $Size-M
    margin-right: 5px

.form__input
    width: 100%
    padding: 10px 5px
    font-size: $Size-S
    outline: none
    border: none
    border-bottom: 2px solid $bg-greenmint
    background-color: $txt-middlelightgrey

.btn-group
    width: 100%
    display: flex
    justify-content: space-between
    
.form__btn
    width: 45%
    height: 40px
    padding: 5px 10px
    outline: none
    border: none
    border-radius: 0px 6px
    margin-bottom: 1.25rem
    font-size: $Size-S
    color: $txt-lightgrey
    background-color: $bg-greenmint
    cursor: pointer
    transition: all .3s
    &:hover
        background-color: $bg-greengrass

.form__btn--cancel
    border-radius: 0px 6px
    background-color: #e74c3c
    &:hover
        background-color: $A-rederror

.formLinks
    width: 100%
    display: flex
    flex-direction: column
    justify-content: space-between

.formLinks__registrar, .formLinks__forgot, .formLinks__icon
    color: $txt-darkblue
    font-size: $Size-S
    font-weight: 600
    margin-bottom: 10px

    &:hover
        cursor: pointer
        text-decoration: underline

.formLinks__icon
    margin-right: 2.5px

.box__errror
    margin-bottom: 1.125rem

.msg__error
    display: flex
    padding: 10px
    text-align: center
    align-items: center
    justify-content: center
    justify-self: center
    align-self: center
    font-weight: 600
    font-size: $Size-S
    color: $A-rederror
    margin: 10px 0px
    width: 100%
    height: auto
    background: transparentize($A-rederror, 0.8)

.login__password__seeIcon
    position: absolute
    right: 20px
    font-size: $Size-L
    fill: $bg-greenmint


.formLinks__title
    align-self: center

.login__google
    width: max-content
    align-self: center
    margin: 15px 0px
    height: 40px
    display: flex
    align-items: center
    background: #4285f4
    justify-content: space-between
    border: 1px solid #4285f4
    border-radius: 5px
    font-family: $font-Exo
    font-weight: 600
    font-size: $Size-S
    padding-right: 8px
    padding-left: 1px
    box-shadow: $box-shadow
    cursor: pointer
    &:hover
        background: $bg-greenmint
        color: $txt-white
        border: 1px solid $bg-greenmint
        transform: scale(1.01)
        transition: all $speedBtn
        

.login__google__icon
    margin-right: 10px
    background: $bg-white
    padding: 5px
    width: 36px
    height: 36px
    border-radius: 5px 2px 2px 5px

.login__google__text
    color: $txt-white 
    
.terms
    animation: Modal-swipeRight 0.2s ease-in
    position: fixed
    display: flex
    justify-content: center
    align-items: center
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1000
    background: rgba(0, 0, 0, 0.5)
    transition: all 0.5s

.terms__content
    position: relative
    width: 80%
    background: $bg-white
    padding: 30px
    height: auto
    overflow-y: scroll
    text-align: center
    
.reset__btn-group
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

.reset__btn
    width: max-content !important
.modalResetPassword
    height: max-content
    max-height: 500px
    &__form
    justify-content: center

.resetSuccess
    position: fixed
    top: 0px
    left: 0px
    background: rgba(0, 0, 0, 0.5)
    height: 100vh
    width: 100%
    padding: 20px
    display: flex
    align-items: center
    justify-content: center
    &__content
        background: linear-gradient(35deg,$bg-greengrass,$txt-white 20%, $txt-lightgrey, $txt-middlelightgrey, $bg-greenmint )
        display: flex
        flex-direction: column
        align-items: center
        height: max-content
        width: 100%
        max-width: 350px
        padding: 15px
    &__text
        font-family: $font-Exo
        font-size: $Size-M
        font-weight: 400
        margin: 15px 0px
        text-align: center
    &__icon
        font-size: 50px
        filter: drop-shadow(1px 2px 3px $txt-darkblue)
        color: $txt-lightblue


/* ---------------- Medium large screen style------------------ */
@media only screen and (min-width: 728px)
    .modalIngreso
        width: 50%
        height: auto
    
    .terms__content
        max-width: 350px

@media only screen and (min-width: 1024px)

    .formLinks__registrar, .formLinks__forgot, .formLinks__icon
        font-size: $Size-S

    .modalIngreso
        width: 20%
        top: 120px
    .resetSuccess
        width: 100%
        