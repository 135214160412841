



.preview
    width: 100%
    height: 100%
    overflow: hidden
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    &__content
        width: 100%
        display: flex
        flex-wrap: wrap
        justify-content: center
        align-items: center
        padding: 20px
    &__title
        font-family: $font-Cairo
        width: 100%
        text-align: center
    &__infoText
        text-align: justify
        margin: 15px 0px
        max-width: 450px
        font-size: $Size-M
    &__imgContainer
        width: 100%
        margin: 15px 0px
        max-width: 600px
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
    &__captcha
        text-align: center
        justify-self: center
        align-self: center
    &__img
        width: 100%
        object-fit: cover
        height: 200px
        background: $bg-white
        border: none
        box-shadow: $box-shadow
        overflow: hidden
    &__tipoPago
        margin: 15px 0px
        display: flex
        flex-direction: column
        box-shadow: $box-shadow
        padding: 15px
        background: $bg-grey
        width: max-content
        min-height: 300px
    &__subtitle
        text-align: center
        width: 100%
        font-family: $font-Cairo
        font-size: $Size-L
        font-weight: 600
        color: $bg-greenmint
        margin-bottom: 25px
    &__input-group
        width: 100%
        padding: 10px
        height: 60px
        border: 1px solid $txt-middlegrey
        display: flex
        align-items: center
        // justify-content: center
        margin: 5px 0px
        cursor: pointer
        font-family: $font-Exo
        &:nth-child(8)
            border: none
            justify-content: center
        &:hover
            // background: $bg-bluegrey
            // color: $txt-white
            // font-weight: 600
            box-shadow: 0px 0px 4px 0px $bg-greenmint
            border: 1px solid $bg-greengrass
            border-radius: 0px 6px
            background: url(#{$assetPath}/icononly_plans.png)
            background-size: cover
            background-repeat: no-repeat
            background-color: $bg-white
            &.preview__input-group:nth-child(8)
                background: none
                border: none
                border-radius: none

        &__label
            display: flex
            align-items: center
            cursor: pointer
            font-size: $Size-M
            font-family: $font-Exo
            font-weight: 400
            &__info
                display: flex
                flex-direction: column
            &__infoData
                display: flex
                flex-direction: row

        &__input
            margin-right: 10px
            width: 25px
            height: 25px
    &__btn
        font-size: $Size-S
        font-family: $font-Exo
        &:hover
            background: $txt-greeneon
        &:disabled
            background: $txt-middlegrey
            color: $txt-lightgrey
    &__note
        font-size: $Size-S
    &__loading__icon
        font-size: $Size-L
        filter: drop-shadow(1px 2px 2px $txt-darkblue)
        animation: rotation 2s infinite linear

.asterisco
    font-size: $Size-L
    color: $A-rederror
    font-weight: 600
    margin-right: 5px

.wompi
    background: $bg-grey
    width: 100%
    max-width: 600px
    padding: 20px
    height: max-content
    margin-top: 50px
    overflow-y: auto
    &__title
        width: 100%
        text-align: center
        position: relative
        font-family: $font-Cairo
        font-weight: 600
        font-size: $Size-L
        text-align: center
        display: flex
        justify-content: center
        align-items: center
        line-height: 25px
        width: 100%
        background: $bg-greenmint
        padding: 15px
        border-radius: 0px 8px
        margin-bottom: 20px
    &__form
        display: flex
        flex-direction: column
        width: 100%
        height: 520px
        align-items: center
        &__controls
            display: flex
            justify-content: space-between
            width: 100%

    &__input
        width: 200px
        height: 40px
        border: 2px solid $bg-greenmint
        border-radius: 0px 8px
        margin-left: 10px
        padding: 5px
        font-family: $font-Exo
        font-size: $Size-M
        justify-self: flex-end
        &__group
            display: flex
            margin: 10px 0px
            width: 100%

        &__label
            font-family: $font-Exo
            font-size: $Size-M
            width: 100%
            justify-content: space-evenly

    &__btn
        padding: 5px
        font-size: $Size-M
        width: 48%
        justify-self: center
        aling-self: center
        margin-top: 20px

.shippingAdress
    display: flex
    flex-direction: column

@media screen and (min-width: 1024px)
    .preview
        display: flex
        justify-content: center
        align-items: center
        &__content
            justify-content: space-evenly
            align-items: center
            max-width: 1200px

.hidden
    display: none