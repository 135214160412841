// @import "./config"

.userInfo__modal
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1000
    background-color: rgba(0, 0, 0, 0.575)
    transition: all 0.5s
    display: flex
    align-items: center
    justify-content: center

/* Modal para editar la informacion */
.userInfo__modal__content
    background-color: $txt-white
    width: 90%
    height: 95%
    max-height: 650px
    overflow-y: scroll

/* Clase agregada desde js */

.userInfo__modal__controls
    width: 100%
    display: flex
    justify-content: space-between
    background-color: $bg-darkgrey
    padding: 1rem

.userInfo__modal__title
    font-size: 1.2rem
    position: relative
    font-family: $font-Cairo
    font-weight: 600
    font-size: $Size-L
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    line-height: 25px
    width: 100%
    background: $bg-greenmint
    padding: 15px
    border-radius: 0px 8px

.userInfo__modal__close
    font-size: 1.2rem
    color: $txt-darkblue
    cursor: pointer

/* Estilos del formulario */
.userInfo__modal__form
    padding: 1rem

.userInfo__modal__w50,
.userInfo__modal__w100
    margin-bottom: 0.9rem

.userInfo__modal__label
    display: block
    width: 100%
    margin-bottom: 0.25rem
    font-size: $Size-M
    font-weight: 500

.userInfo__modal__input
    width: 100%
    display: block
    padding: 0.8rem
    font-size: 0.8rem
    border: none
    border-bottom: 1px solid $bg-bluegrey
    background-color: $txt-lightgrey
    color: $txt-darkblue

.input__list
    -webkit--min-width: 100%
    -moz--min-width: 100%
    min-width: 96%
    -webkit--appearance: none
    -moz--appearance: none

.input__list:hover
    cursor: pointer

.userInfo__modal__w100 textarea
    resize: none

.userInfo__modal__boxBtn
    display: flex
    justify-content: flex-end
    margin-top: 1rem

.userInfo__modal__Btn
    margin-left: 10px


.userInfo__modal__Btn:hover
    opacity: 0.9

.userSocial__modal__linkSocial
    display: flex
    align-items: center

.socialLink
    height: 35px
    box-shadow: 0px 0px 2px 0px $txt-black
    border: none
    background: $bg-white
    border-radius: 0px 6px
    margin: 15px 0px


@media only screen and (min-width: 540px)
    .userInfo__modal__form
        display: flex
        justify-content: space-between
        flex-wrap: wrap
        // overflow-y: scroll

    .userInfo__modal__w50
        width: 48%

    .userInfo__modal__w100
        width: 100%

@media only screen and (min-width: 748px)
    .userInfo__modal__title
        font-size: 1.6rem

    .userInfo__modal__close
        font-size: 1.35rem

    .userInfo__modal__w50,
    .userInfo__modal__w100
        margin-bottom: 1rem

    .userInfo__modal__label
        font-size: $Size-M

    .userInfo__modal__input
        font-size: 1.15rem

@media only screen and (min-width: 1024px)
    .userInfo__modal__title
        font-size: 1.7rem

    .userInfo__modal__close
        font-size: 1.45rem

    .userInfo__modal__w50,
    .userInfo__modal__w100
        margin-bottom: 1.1rem

    .userInfo__modal__label
        font-size: $Size-M

    .userInfo__modal__input
        font-size: 1.2rem
