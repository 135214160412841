/* STYLES PORTADA */
.user__portada 
  width: 100%
  position: relative
  /* z-index: -11 */

.user__portadaImg 
  width: 100%
  height: 16em
  object-fit: cover

.user__uploadPortada 
  padding: 0.5rem
  position: absolute
  bottom: 1rem
  right: 1rem
  border-radius: 50%
  font-size: 1rem
  background-color: $txt-lightgrey
  transition: background 0.5s
  cursor: pointer

.user__uploadBoxPortada 
  cursor: pointer

.user__uploadPortada:hover 
  background: linear-gradient(35deg,$txt-white 20%, $txt-lightgrey, $txt-middlelightgrey )

.user__textPortada 
  display: none

#savePortada 
  display: none


@media only screen and (min-width: 748px)
  /* STYLES PORTADA */
  .user__uploadPortada 
    bottom: 1.5rem
    right: 1.5rem
    font-size: 1.2rem
  
  .user__textPortada 
    margin-left: 0.25em
  
  .user__portadaImg
    height: 250px
    width: 100%
    object-fit: cover

@media only screen and (min-width: 1024px)
  .user__portadaImg
    height: 250px
  