*
  padding: 0
  margin: 0
  box-sizing: border-box

a
  text-decoration: none

li
  list-style: none

/*------ Fonts families ---------- */
$font-Exo: "Exo 2", sans-serif
$font-Cairo: 'Cairo', sans-serif
$font-IBM: 'IBM Plex Sans', sans-serif

/*------ Colors families ---------- */

/* Colores Fondos */
$bg-greenmint: #3CB8B4
$bg-greengrass: #41DD9B
$bg-bluepurple: #4877C4
$bg-bluegrey: #1b262c
$bg-fucsia: #900048
$bg-darkgrey: #545455
$bg-grey: #E5E5E5
$bg-white: #f6f5f5

/* Colores Texto */
$txt-greeneon: #54e346
$txt-darkblue: #283149
$txt-lightblue: #013a63
$txt-white: #E5E5E5
$txt-lightgrey: #efefef
$txt-middlelightgrey: #e4e4e4
$txt-middlegrey: #cacaca
$txt-darkgrey: #545455
$txt-black: #030808

/* Colores Animaciones */
$A-rederror: #d63031
$A-fucsialight: #e41749
$A-orange: #e47b19
$A-lightblue: #41b3ff
$A-darkgrey: #797a7e

/*-------- Font size -----------*/

$Size-XXL: 40px
$Size-XL: 32px
$Size-L: 26px
$Size-M: 20px
$Size-S: 16px
$Size-XS: 12px
$Size-XXS: 10px

/*------------- Animations ---------------*/
$speed: 400ms
$speedBtn: 200ms
$assetPath: "/assets/img"
$box-shadow: 0px 0px 4px 0px $txt-darkblue

body
  // overflow: hidden
  font-family: $font-IBM

h1
  font-family: $font-Cairo

.button-primary
  padding: 0px 8px
  height: 40px
  outline: none
  border: none
  font-family: $font-Exo
  font-weight: 600
  font-size: $Size-M
  cursor: pointer
  transition: 0.3s
  background: $bg-greengrass
  box-shadow: $box-shadow
  border-radius: 0px 6px
  color: $txt-darkblue
  display: flex
  align-items: center
  justify-content: center
  &:active
    background: $bg-greenmint
    box-shadow: inset 2px 2px 6px rgba(0, 0, 0, 0.3)
  &:hover
    transform: scale(1.03)
    transition: all $speedBtn

.button-secondary
  padding: 0px 8px
  height: 40px
  outline: none
  font-family: $font-Exo
  font-weight: 600
  font-size: $Size-M
  cursor: pointer
  transition: $speedBtn
  background: $txt-white
  border: 2px solid $bg-greenmint
  border-radius: 0px 6px
  color: $bg-greenmint
  display: flex
  align-items: center
  justify-content: center
  &:active
    background: $bg-greengrass
    box-shadow: inset 2px 2px 6px rgba(0, 0, 0, 0.3)
    border: 2px solid $bg-greengrass
    color: $txt-white
  &:hover
    transform: scale(1.03)
    transition: all $speedBtn
.separacionBtn
  margin-left: 10px
  margin-right: 10px

@mixin btnStyle($bg, $box_shadow: none, $font: 15px, $height: 40px)
  background-color: $bg
  box-shadow: $box_shadow
  font-size: $font
  height: $height

@mixin boxShadow ($blur: 8px, $color: #4877C4)
  box-shadow: 0px 0px $blur 0px $color

@keyframes Modal-swipeRight
  0%
    left: -100%

  100%
    left: 0%

@keyframes Modal-swipeLeft
  0%
    right: -100%

  100%
    right: 0%

@keyframes Modal-widthLeft
  0%
    width: 0px

  100%
    width: 250px

@keyframes Modal-swipeLeft-close
  0%
    right: -0%

  100%
    right: -100%

@keyframes rotation
  from 
    -webkit-transform: rotate(0deg)

  to 
    -webkit-transform: rotate(359deg)
