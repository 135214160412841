

.footer
    width: 100%
    // height: 350px
    background: $bg-darkgrey

.footer__content
    display: flex
    flex-direction: column
    justify-content: center
    width: 100%

.footer__content__info,
.footer__content__social,
.footer__content__copyright
    width: 100%
    padding: 1.25rem

.footer__content__info
    display: flex
    flex-direction: column

.footer__logo
    width: 150px
    object-fit: cover
    margin: 20px 0px

.footer__info__link
    color: $txt-white
    margin-bottom: 10px
    font-family: $font-Cairo
    font-size: $Size-S
    font-weight: 700
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
    width: max-content
    cursor: pointer
    &:hover
        color: $bg-greenmint

.footer__content__social
    display: flex
    flex-direction: column

.footer__info__title, .footer__social__title
    color: $bg-greengrass
    font-weight: 400
    font-size: $Size-L
    margin-bottom: 20px

.social__content
    display: flex
    flex-direction: column

.social__link
    padding: 10px 0px
    color: $txt-white
    display: flex
    align-items: center
    &:hover
        color: $bg-greenmint
        .facebook
            color: #1b74e4
        .linkedin
            color: #3777c5
        .twitter
            color: #00acee
        .youtube
            color: #c4302b
        .instagram
            color: white
            background: #d6249f
            background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)
            box-shadow: 0px 3px 10px rgba(0,0,0,.25)
            border-radius: 10px

.social__link__label
    margin-left: 8px
    font-family: $font-Cairo
    font-size: $Size-M
    font-weight: 600
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
    cursor: pointer


.social__icon
    width: 42px
    height: 45px
    filter: drop-shadow(0px 4px 4px #545455)

.copyright__text
    color: $txt-white
    font-family: $font-Cairo
    font-size: $Size-S
    text-align: center

.footer__content__logo
    display: none

@media screen and (min-width: 728px)
    .footer__content
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        justify-content: center
        width: 100%

    .footer__content__social
        justify-content: center
        align-items: center

    .footer__logo__img
        width: 130px
        height: 120px
        object-fit: fill
        margin-right: 30px

    .footer__content__copyright
        grid-column: span 3
        width: 100%
    
    .footer__content__logo
        display: flex
        align-items: center
        justify-content: flex-end
    
    .social__content
        display: grid
        grid-template-columns: 1fr 1fr
        column-gap: 30px
        max-width: 300px

    .footer__social__title
        text-align: center