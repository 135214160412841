

.modal
    animation: Modal-swipeRight 0.2s ease-in
    position: fixed
    display: flex
    justify-content: center
    align-items: center
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1000
    background: rgba(0, 0, 0, 0.5)
    transition: all 0.5s

.modal__content
    position: relative
    width: 80%
    max-width: 650px
    background: $bg-white
    padding: 20px
    height: max-content
    max-height: 80%
    overflow-y: scroll
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

.modal__closeIcon
    font-size: $Size-XL
    color: $bg-darkgrey
    z-index: 1000
    position: absolute
    right: 10px
    top: 10px

.advice__title
    font-family: $font-Cairo
    font-size: $Size-L
    text-align: center
    margin-bottom: 20px

.advice__subtitle
    font-family: $font-Cairo
    font-size: $Size-M
    text-align: center
    margin-bottom: 10px

.advice__text
    font-family: $font-Exo
    font-size: $Size-S
    text-align: justify
    margin-bottom: 20px

.paymentError
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    justify-self: center
    text-align: center
    width: 100%
    margin: 20px 0px
    &__text
        width: 80%
        font-size: $Size-L
        margin-bottom: 10px
    &__Icon
        font-size: 45px
        margin-bottom: 15px
        color: $A-rederror
        filter: drop-shadow(2px 1px 3px $A-darkgrey)

        

/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/
.modal__content::-webkit-scrollbar
    -webkit-appearance: none

.modal__content::-webkit-scrollbar:vertical
    width: 5px

.modal__content::-webkit-scrollbar-button:increment,.modal__content::-webkit-scrollbar-button
    display: none

.modal__content::-webkit-scrollbar:horizontal
    height: 10px

.modal__content::-webkit-scrollbar-thumb
    background-color: $bg-greengrass
    border-radius: 20px
    border: 2px solid $bg-greenmint

.modal__content::-webkit-scrollbar-track
    border-radius: 10px

