

.notFound
    width: 100%
    padding: 15px

    &__info
        display: flex
        flex-direction: column
        // grid-template-columns: 1fr 1fr
        gap: 10px
        align-items: center
        justify-content: center
        margin: 20px 0px
    &__title
        color: $txt-darkblue
        font-family: $font-Cairo
        font-size: $Size-XXL
        grid-column: span 2
        text-align: center
    &__parrafo
        font-size: $Size-S
        font-weight: 400
        text-align: justify
    
    &__img
        width: 100%

@media only screen and (min-width: 728px)
   .notFound
        
        &__info
            grid-template-columns: 1fr 2fr
            margin: 30px
        &__title
            grid-column: 2
            text-align: center
        &__parrafo
            font-size: $Size-L
            font-weight: 400
            grid-column: 2
            align-self: flex-start

@media only screen and (min-width: 1024px)
   .notFound
        
        &__info
            grid-template-columns: 1fr 1fr
            margin: 0px
            margin-left: auto
            margin-right: auto
            width: 50%
        &__title
            grid-column: span 2
            text-align: center
            width: 100%
        &__parrafo
            font-size: $Size-L
            font-weight: 400
            width: 100%
            grid-column: 2
            align-self: center
        &__img
            width: 100%