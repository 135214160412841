

.aboutus
    padding: 15px
    overflow: hidden

.background__img__start,
.background__img__middle,
.background__img__end
    position: absolute
    z-index: -100
    opacity: 0.3

.background__img__start
    width: 510px
    height: 413px
    left: -327px
    top: 310px

.background__img__middle
    width: 585px
    height: 443px
    left: -340px
    top: 1450px
    transform: scaleX(-1)

.background__img__end
    top: 3000px
    right: 0
    height: 443px


.vision__text,
.philosophy__text
    font-family: $font-IBM
    font-style: normal
    font-weight: normal
    font-size: $Size-M
    text-align: justify
    width: 100%

.vision__title,
.value__title,
.philosophy__title
    font-family: $font-Cairo
    font-style: normal
    font-weight: 700
    font-size: $Size-XL
    display: flex
    justify-content: center

.mision
    display: grid
    grid-template-columns: 2fr 1fr

    &__title
        font-family: $font-Cairo
        font-style: normal
        font-weight: 700
        font-size: $Size-XL
        line-height: 67px
        display: flex
        justify-content: center
        color: $bg-greenmint
        grid-column: span 2

    &__text
        font-family: $font-IBM
        font-style: normal
        font-weight: normal
        font-size: $Size-M
        text-align: justify
        width: 100%
    
    &__img
        width: 110%
        height: 320px
        object-fit: fill

.vision
    padding: 15px
    display: grid
    grid-template-columns: 1fr 2fr
    justify-content: center

    &__title
        grid-column: span 2
    
    &__img
        width: 100%
        height: 320px
        object-fit: fill
        grid-column: 1
        grid-row: 2
        z-index: -10
        
    &__text
        grid-column: 2
        width: 100%

.value
    
    display: grid
    grid-template-columns: 1fr 1fr

    &__title
        font-family: $font-Cairo
        font-style: normal
        font-weight: 700
        font-size: $Size-XL
        display: flex
        justify-content: center
        color: $bg-greenmint
        grid-column: span 2
    
    &__text
        font-family: $font-IBM
        font-size: $Size-M
        line-height: 23px
        text-align: justify
        width: 100%
    
    &__subtext
        margin: 15px 0px
    
    &__img
        width: 100%
        height: 420px
        object-fit: fill
        align-self: center
    
    &__text__icon
        color: $txt-lightblue
        font-size: $Size-L
        margin-right: 8px

.philosophy
    display: grid
    grid-template-columns: 1fr 1fr
    width: 100%
    overflow: hidden
    
    &__title
        grid-column: span 2
    
    &__content
        grid-column: 2
        margin-bottom: 20px

    &__img1
        grid-column: 1
        grid-row: 2/4
        transform: scaleX(-1)
        width: 140px
        height: 420px
        align-self: center
        justify-self: center

    &__count
        color: $txt-middlegrey
        font-size: $Size-XL
        font-family: $font-Exo
    &__subtitle
        color: $bg-greengrass
        font-size: $Size-L
    
    &__img2
        grid-column: 2
        grid-row: 4/7
        align-self: center
        width: 160px
        height: 520px
        justify-self: center

.reverse
    grid-column: 1

@media screen and (min-width: 728px)

    .background__img__start
        width: 610px
        height: 513px
        left: -320px
        top: 300px

    .background__img__middle
        width: 610px
        height: 443px
        left: -300px
        top: 1350px
        transform: scaleX(-1)

    .background__img__end
        top: 2200px
        right: 0
        height: 443px

    .aboutus
        display: grid

    .mision
        width: 70%
        column-gap: 20px
        justify-self: center

        &__title
            grid-column: 1
            align-self: flex-end

        &__text
            grid-column: 1

        &__img
            width: 100%
            height: 420px
            grid-row: 1/3
            grid-column: 2

    .vision
        width: 70%
        column-gap: 20px
        justify-self: center

        &__title
            grid-column: 2
            align-self: flex-end

        &__text
            grid-column: 2

        &__img
            width: 100%
            height: 420px
            grid-row: 1/3
            grid-column: 1

    .value
        width: 70%
        column-gap: 20px
        justify-self: center

        &__text
            grid-column: 1

        &__img
            width: 100%
            height: 520px
            grid-column: 2

@media screen and (min-width: 1024px)

    .background__img__start
        width: 990px
        height: 700px
        left: -520px
        top: 400px
        opacity: 0.6

    .background__img__middle
        width: 490px
        height: 380px
        left: -160px
        top: 2620px
        transform: scaleX(1)
        opacity: 0.6

    .background__img__end
        height: 700px
        top: 1250px
        right: 0
        opacity: 0.6

    .aboutus
        display: grid

    .mision
        width: 80%
        justify-self: center
        grid-row: 1
        grid-column: span 2
        margin-top: 30px
        grid-template-rows: 1fr 3fr

        &__text
            width: 80%
            justify-self: center

        &__img
            height: 680px

    .vision
        margin-top: 30px
        column-gap: 20px
        justify-self: flex-start
        grid-row: 2
        grid-column: 2

        &__title
            grid-column: span 2
            align-self: center

        &__text
            grid-column: 2
            margin-left: 50px
            margin-top: 30px

        &__img
            width: 120%
            height: 520px
            grid-row: 2/3
            grid-column: 1

    .value
        margin-top: 30px
        width: 80%
        column-gap: 20px
        justify-self: end
        grid-row: 2
        grid-column: 1

        &__title
            color: $txt-black
            align-self: flex-end

        &__img
            width: 80%
            height: 520px
            grid-column: 2
    
    .philosophy
        grid-row: 3
        grid-column: span 2
        width: 80%
        justify-self: center
        margin-top: 50px
        margin-bottom: 100px

    
@media screen and (min-width: 1420px)

    .background__img__start
        width: 1020px
        height: 760px
        left: -520px
        top: 300px
        opacity: 0.8

    .background__img__middle
        width: 490px
        height: 380px
        left: -160px
        top: 2800px
        transform: scaleX(1)
        opacity: 0.8

    .background__img__end
        height: 700px
        top: 1250px
        right: 0
        opacity: 0.8

    .mision
        width: 60%

        &__title
            font-size: 45px

        &__text
            width: 80%
            justify-self: center
            font-size: $Size-L
            font-weight: 400

        &__img
            width: 90%
            height: 680px

    .vision

        &__title
            font-size: 45px

        &__text
            font-size: $Size-L
            font-weight: 400

    .value
        &__title
            font-size: 45px

        &__text
            font-size: $Size-L
            font-weight: 400
    
    .philosophy
        width: 75%

        &__title
            font-size: 45px
            color: $bg-greenmint

        &__subtitle
            font-size: $Size-XL

        &__count
            font-size: 42px

        &__text
            font-size: $Size-L
            font-weight: 400
        
        &__img1
            width: 180px
            height: 480px
    
        &__img2
            width: 200px
            height: 520px
