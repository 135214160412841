// @import "./config"
/* STYLES USER INFO*/
.user__info
  width: 100%
  padding: 1rem

.user__personalInfo
  width: 100%
  text-align: center

.user__name
  font-size: $Size-XL
  font-weight: 600
  margin-bottom: 0.25rem

.user__username
  font-size: $Size-M
  font-weight: 400
  color: $A-darkgrey

.user__edit
  margin-top: 15px

/* STYLES INFO ADITIONAL */
.user__infoAditional
  width: 100%
  margin-top: 0.625rem

.user__Fields
  margin-bottom: 1rem

.user__titleField
  font-family: $font-Exo
  font-size: 1.3rem
  margin-bottom: 0.5rem
  font-weight: 600

.user__valueField
  display: block
  width: 100%
  height: 2rem
  padding: 0.3rem
  font-size: 1.2em
  background-color: $txt-lightgrey
  box-shadow: $box-shadow

.user__aboutMe
  height: 5rem
  padding: 0.5rem 0.25rem

/* STYLES SOCIAL MEDIA */
.user__social
  margin: 2rem 0rem
  display: flex
  flex-direction: column

.user__title
  font-size: $Size-M
  margin-bottom: 0.8rem
  display: flex
  justify-content: space-between
  align-items: center

.user__title__content
  display: flex
  align-items: center

.title__SocialIcon
  margin-right: 0.625rem
  width: 1.5625rem
  height: 1.5625rem
  fill: $bg-bluegrey
  font-weight: 600

/* btn desplegar modal  */
.social__iconEdit
  margin-left: 1.25rem
  font-size: 20.8px
  text-align: end

.user__linkSocial
  font-family: $font-Exo
  text-decoration: none
  color: $txt-darkgrey
  cursor: pointer
  display: flex
  align-items: center

.user__linkSocial:hover
  color: $A-darkgrey

.user__textSocial
  margin-left: 0.25em
  font-size: 1.2rem

.user__iconSocial
  width: 35px
  height: 35px
  border-radius: 7.2px
  padding: 4px 4.8px
  margin-bottom: 11.2px

.user__linkSocial
  &:hover
    color: $bg-greenmint
    .facebook
        color: #1b74e4
    .linkedin
        color: #3777c5
    .twitter
        color: #00acee
    .instagram
        color: $txt-white
        background: #d6249f
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)
        box-shadow: 0px 3px 10px rgba(0,0,0,.25)
        border-radius: 10px

.menu__social__iconPerfil
  color: $bg-greenmint
  width: 20px
  height: 20px
  margin-right: 10px
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))

.backgroundUser__img__start
  position: absolute
  top: 50%
  left: -50%
  height: 300px
  z-index: -1
  opacity: 0.4

.backgroundUser__img__end
  position: absolute
  bottom: -500px
  right: 0
  height: 300px
  z-index: -1
  opacity: 0.4


@media only screen and (min-width: 748px)
  /* STYLES USER INFO*/
  .user__info
    width: 100%
    padding: 1.5rem
    display: flex
    flex-wrap: wrap
    justify-content: space-between

  .user__personalInfo
    text-align: center

  .user__name
    font-size: 2rem

  .user__username
    font-size: 1.5rem

  /* STYLES INFO ADITIONAL */
  .user__infoAditional
    display: flex
    flex-wrap: wrap
    justify-content: space-between

  .user__Fields
    margin-bottom: 1.25rem

  .user__titleField
    font-size: 1.35rem
    margin-bottom: 0.55rem

  .user__valueField
    padding: 0.3rem
    font-size: 1.25em

  /* STYLES SOCIAL MEDIA */
  .user__linkSocial
    width: auto

  /* STYLES BTN EDIT */
  .user__edit
    top: 25.5rem
    left: unset
    right: 1.5rem

  .user__iconEdit
    font-size: $Size-L
    filter: drop-shadow(1px 1px 2px $txt-black)
  .user__textEdit
    font-size: $Size-M
    filter: drop-shadow(1px 1px 2px $txt-black)

  .user__textEdit
    margin-left: 0.25em

  /* STYLES SOCIAL MEDIA */
  .user__social
    width: 100%
    flex-direction: row
    flex-wrap: wrap
    justify-content: space-between

  .user__linkSocial
    width: 50%
  
  .backgroundUser__img__start
    top: 50%
    left: -25%

  .backgroundUser__img__end
      top: 100%
      right: 0


@media only screen and (min-width: 1024px)
  .user__info
    display: grid
    grid-template-columns: 2fr 1fr
    margin: 0px auto
    width: 100%
    column-gap: 2.5rem
    row-gap: 0.625rem

  .user
    &__personalInfo
      display: flex
      flex-direction: column
      align-items: flex-start

    &__social
      width: 100%
      flex-direction: column
      justify-content: flex-start
      gap: 0.5rem
      width: 100%
      box-shadow: 0.125rem 0.125rem 0.375rem 0rem $txt-darkgrey
      padding: 0.9375rem
      height: 250px
      background: linear-gradient(35deg,$txt-white,$txt-lightgrey)
      grid-row: 3
      grid-column: 2
      margin-top: -100px

    &__biography
      width: 60%
      margin-top: 1em
      margin-bottom: 0

    &__edit
      width: fit-content
      display: flex
      // margin-left: -250px
      align-items: center
      cursor: pointer
      grid-row: 2
    &__infoAditional
      display: grid
      grid-template-columns: 1fr 1fr
      column-gap: 15px
      grid-row: 3

  .menu__social__iconPerfil
    width: 35px
    height: 35px

  .backgroundUser__img__start
    position: absolute
    top: 45%
    left: -5%
    height: 443px
    z-index: -1
    opacity: 0.4

  .user__valueField
    max-width: 350px

  .backgroundUser__img__end
    position: absolute
    top: 78%
    right: 0
    height: 443px
    z-index: -1
    opacity: 0.6
  
  .userPlan
    grid-row: 4
    grid-column: 2
    margin-top: -280px
