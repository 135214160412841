

strong 
    font-weight: 600
.confirm
    background: $txt-white
    padding: 15px
    box-shadow: $box-shadow
    overflow-y: scroll
    height: 520px

    &__title
        position: relative
        font-family: $font-Cairo
        font-weight: 600
        font-size: $Size-L
        text-align: center
        display: flex
        justify-content: center
        align-items: center
        line-height: 25px
        width: 100%
        background: $bg-greenmint
        padding: 15px
        border-radius: 0px 8px
    &__subtitle
        position: relative
        font-family: $font-Cairo
        font-weight: 600
        font-size: $Size-M
        text-align: center
        display: flex
        justify-content: center
        align-items: center
        line-height: 10px
        width: max-content
        padding: 15px
        border: 2px solid $bg-greenmint
        border-radius: 0px 8px
        margin: 25px 0px
    &__btn
        width: 100%
        display: flex
        justify-content: center
        margin-top: 15px
    &__closeIcon
        font-size: $Size-XL
        color: $bg-darkgrey
        z-index: 1000
        position: absolute
        right: 10px
        top: 10px
        cursor: pointer
    &__addIcon
        font-size: $Size-XL
        color: $bg-darkgrey
        z-index: 1000
        cursor: pointer
    &__label
        width: 100%
        font-size: 18px
        font-weight: 400
        font-family: $font-Exo
        display: flex
        justify-content: space-between
    &__dateTCuadro
        display: flex
        flex-direction: column
    &__caract
        display: grid
        grid-row-gap: 10px
    
    &__flow
        &__content
            display: grid
            grid-row-gap: 10px
        &__infoTitle
            margin-top: 15px
            margin-bottom: 15px
            border-bottom: 2px solid $bg-greenmint

.editIcon
    margin-left: 10px
    border: 2px solid $txt-lightblue
    padding: 5px
    width: 35px
    height: 35px
    color: $txt-darkblue
    font-size: $Size-L
    border-radius: 50%
    box-shadow: $box-shadow

.contrato
    margin-top: 10px
    border-top: 2px solid $bg-greenmint
.horasContrato
    display: flex
    flex-direction: column
    &__info
        margin-left: 20px
.fechasRestricted
    display: grid
    margin-top: 15px

/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/

.confirm::-webkit-scrollbar
    -webkit-appearance: none

.confirm::-webkit-scrollbar:vertical
    width: 5px

.confirm::-webkit-scrollbar-button:increment,.confirm::-webkit-scrollbar-button
    display: none

.confirm::-webkit-scrollbar:horizontal
    height: 10px

.confirm::-webkit-scrollbar-thumb
    background-color: $bg-greengrass
    border-radius: 20px
    border: 2px solid $bg-greenmint

.confirm::-webkit-scrollbar-track
    border-radius: 10px

@media screen and (min-width: 728px)
    .confirm
        width: 600px
        margin-top: 50px
        height: max-content
        max-height: 600px
