

@keyframes loading 
    0%
        opacity: 0%
    100%
        opacity: 100%

.loading
    position: fixed
    top: 0px
    left: 0px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    z-index: 1000
    width: 100%
    height: 100%
    background: transparentize($bg-white, 0.1)

    &__icon
        animation: loading 0.4s infinite alternate linear
        transform-origin: center right
        width: 25%
        object-fit: cover

.load
    margin-top: 20px

@media screen and (min-width: 1024px)
    .loading__icon
        width: 10%