// @import "./config"

.plans
    padding: 20px
    margin: 20px 0px
    &__title
        font-family: $font-Cairo
        font-weight: 400
        font-size: $Size-XL
        text-align: center
        line-height: 32px
        color: $txt-darkblue
    &__subtitle
        color: $txt-darkgrey
        text-align: center
        font-family: $font-Cairo
        font-size: $Size-M
        font-weight: 400
        margin-top: 20px
        margin-bottom: 20px
    &__container
        display: flex
        justify-content: center
        align-items: center
    &__tab
        display: flex
        flex-wrap: wrap
        justify-content: space-evenly
        box-shadow: $box-shadow
        width: 100%
        background: $bg-white
        border-radius: 5px
        padding: 5px

    &__content
        display: flex
        flex-direction: column
        justify-content: space-between
        align-items: center
        width: 100%
        max-width: 350px
        padding: 20px
        margin-top: 15px
        margin-bottom: 15px
        background: $txt-lightgrey
        transition: all $speedBtn
        box-shadow: 0px 0px 2px 0px $A-darkgrey
        &:hover
            transform: scale(1.02)
            box-shadow: 0px 0px 4px 0px $bg-greenmint
            border: 1px solid $bg-greengrass
            border-radius: 0px 6px
            background: url(#{$assetPath}/icononly_plans.png)
            background-size: cover
            background-repeat: no-repeat
            background-color: $bg-white
        &:hover > .plans__content__info > .plans__content__label
            font-weight: 600
        
        &__planActual
            text-align: center
            color: $txt-white
            background: $txt-darkblue
            padding: 2px
            border-radius: 6px 0px 6px 0px

        &__title
            margin-bottom: 25px
            font-family: $font-Cairo
            font-size: $Size-XL
            color: $txt-darkblue
            line-height: 35px
        &__price
            font-family: $font-Exo
            margin-bottom: 25px
            font-size: $Size-L
            color: $txt-black
            position: relative
            display: flex
        &__descripcion
            margin-bottom: 20px
        
        &__info
            display: grid
            grid-template-columns: 2fr 1fr
        &__data
            text-align: center
            font-family: $font-Exo
            font-weight: 500
        &__subtitle
            margin-top: 15px
            margin-bottom: 8px
            font-size: $Size-S
            font-family: $font-Cairo
        &__subinfo
            display: flex
            align-items: center
            margin-bottom: 8px
        &__checkIcon
            margin-right: 8px
            font-size: $Size-M
            color: $bg-greenmint
            filter: drop-shadow(1px 1px 1px $txt-black)
        &__notIcon
            margin-right: 8px
            font-size: $Size-M
            color: $A-rederror
            filter: drop-shadow(1px 1px 1px $txt-black)
        &__btn
            margin: 15px 0px
            align-self: center
            justify-self: flex-end
            width: 80%
        &__label2
            display: flex
            align-items: center

.controls
    position: relative
    display: flex
    align-items: center
.info
    display: none
    width: 150px
    background: $txt-darkblue
    color: $txt-white
    padding: 5px
    width: max-content
    &__text
        font-family: $font-Exo
        font-size: $Size-S
        line-height: 15px
    &__value
        font-family: $font-Exo
        font-size: $Size-S



.price__infoIcon
    font-size: $Size-S
    color: $bg-bluegrey
    cursor: pointer
    margin-left: 8px
    margin-right: 8px
    &:hover + .info
        display: flex
        flex-direction: column


.info__active
    display: block

.small
    font-size: 12px

@media screen and (min-width: 1024px)

    .plans__title
        font-size: 50px
        line-height: 55px