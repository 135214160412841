/* STYLES USER AVATAR */
.user__avatar 
  width: 10rem
  height: 10rem
  background-color: $txt-lightgrey
  border-radius: 50%
  margin-top: -6rem
  margin-left: auto
  margin-right: auto
  display: flex
  justify-content: center
  align-items: center
  overflow: hidden
  position: relative
  box-shadow: 0px 4px 4px 0px $A-darkgrey

.user__iconAvatar 
  width: 100%
  height: 100%
  object-fit: cover
  font-size: 8rem
  fill: $txt-darkblue

.user__uploadBox 
  position: absolute
  width: 100%
  padding: 0.3rem 0
  text-align: center
  background-color: $txt-middlelightgrey
  bottom: 0
  transition: 0.3s
  opacity: 0.5
  cursor: pointer

.user__uploadBox:hover 
  opacity: 0.8


.user__iconAvatarUpload 
  font-size: $Size-M
  /* color: $txt-lightgrey */

.user__textAvatar 
  display: none

#saveAvatar 
  display: none

@media only screen and (min-width: 748px)

  /* STYLES USER AVATAR */
  .user__avatar 
    align-self: flex-start
    width: 12rem
    height: 12rem
    margin-top: -7.5rem
    margin-left: 1.5em
  
  .user__iconAvatar 
    font-size: 9rem
    border-radius: 100% 
    /* margin-bottom: 0.1em */
  
  .user__uploadBox 
    padding: 0.6rem 0
  
  .user__iconAvatarUpload 
    font-size: 1.8rem

@media only screen and (min-width: 1024px)
/* STYLES USER AVATAR */
  .user__uploadPortada 
    right: 2rem
    padding: 0.5rem 1rem
  

  /* STYLES USER AVATAR */
  .user__avatar 
    margin-left: 2em
  