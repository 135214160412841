
.modalRegistro
    animation: Modal-swipeLeft $speed ease-in
    position: absolute
    top: 100px
    right: 0px
    width: 100%
    height: 80vh
    display: flex
    transition: all $speed ease
    z-index: 1000
    background-color: $txt-middlelightgrey
    box-shadow: $box-shadow
    overflow-y: auto 

.modalRegistro__form
    padding: 20px
    width: 100%
    display: flex
    flex-direction: column
    

.form__input-box
    width: 100%
    margin-bottom: 1.25rem

.form__label
    display: flex
    width: 100%
    margin-bottom: 0.625rem
    color: $bg-greenmint
    font-size: $Size-M
    font-weight: 800

.form__icon
    font-size: $Size-M
    margin-right: 5px

.form__input
    width: 100%
    padding: 10px 5px
    font-size: $Size-S
    outline: none
    border: none
    border-bottom: 2px solid $bg-greenmint
    background-color: $txt-middlelightgrey

.btn-group
    width: 100%
    display: flex
    justify-content: space-between
    margin-top: 10px
    
.form__btn
    width: 45%
    height: 40px
    padding: 5px 10px
    outline: none
    border: none
    margin-bottom: 1.25rem
    font-size: $Size-S
    color: $txt-lightgrey
    background-color: $bg-greenmint
    cursor: pointer
    transition: all .3s
    &:hover
        background-color: $bg-greengrass

.form__btn--cancel
    background-color: #e74c3c
    &:hover
        background-color: $A-rederror

.formLinks
    width: 100%
    display: flex
    flex-direction: column
    justify-content: space-between

.formLinks__registrar, .formLinks__forgot, .formLinks__icon
    color: $txt-darkblue
    font-size: $Size-S
    font-weight: 600
    margin-bottom: 10px

    &:hover
        cursor: pointer
        text-decoration: underline

.formLinks__icon
    margin-right: 2.5px

.msg__error
    display: flex
    padding: 15px
    align-items: center
    justify-content: center
    justify-self: center
    align-self: center
    font-weight: 600
    font-size: $Size-S
    color: $A-rederror
    margin: 10px 0px
    width: 100%
    height: auto
    background: transparentize($A-rederror, 0.8)

.login__password__seeIcon
    position: absolute
    right: 20px
    font-size: $Size-L
    fill: $bg-greenmint


.formLinks__title
    align-self: center

.form__terms
    margin-top: 20px
    padding: 20px
    display: flex
    align-items: center
    &__text
        word-break: break-all
        font-size: $Size-S
        font-weight: 600
    &__checkbox
        width: 35px
        height: 35px
        margin-right: 10px
        

.form__terms__link
    color: $txt-lightblue
    text-decoration: underline

.form__input-error
    font-size: $Size-XS
    color: $A-rederror
    font-family: $font-Exo
    font-weight: 600
    margin-bottom: 0

/* ---------------- Medium large screen style------------------ */
@media only screen and (min-width: 728px)
    .modalRegistro
        width: 50%
        height: auto

@media only screen and (min-width: 1024px)

    .formLinks__registrar, .formLinks__forgot, .formLinks__icon
        font-size: $Size-S

    .modalRegistro
        width: 20%
        top: 120px
        