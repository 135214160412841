@import "./config"

@keyframes ans-appear
    0%
        opacity: 0

    100%
        opacity: 1

.faq
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    padding: 30px

    &__menu
        display: flex
        flex-direction: column
        justify-content: space-between
        border-bottom: 1px solid $bg-greenmint
    &__menuList
        box-shadow: $box-shadow
        padding: 8px
        border-radius: 0px 6px 0px 6px
        margin-bottom: 8px
        border: none
        cursor: pointer
        font-family: $font-Exo
        font-weight: 600
        font-size: $Size-S
        display: flex
        justify-content: center
        align-items: center
        text-align: center
        width: 100%
        &__active
            animation: ans-appear $speed ease-in
            opacity: 1
            background: $bg-greenmint

    &__title
        font-family: $font-Cairo
        font-size: $Size-XXL
        font-weight: 600
        color: $txt-black
        line-height: 40px
        text-align: center
        width: 100%
    &__content
        margin: 15px 0px
        padding: 10px
    &__list
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
    &__item
        width: 100%
        margin: 15px 0px
        position: relative

    &__header
        box-shadow: $box-shadow
        padding: 8px
        border-radius: 0px 6px 0px 6px
        margin-bottom: 8px
        border: none
        cursor: pointer
        &__active
            animation: ans-appear $speed ease-in
            opacity: 1
            background: $bg-greenmint
    &__count
        color: $txt-middlegrey
        font-size: $Size-L
        font-family: $font-Exo
    &__question
        color: $txt-darkblue
        font-size: $Size-L
    &__ans
        display: none
        position: relative
        opacity: 0
        width: 100%
        text-align: justify
        color: $txt-black
        font-family: $font-Exo
        font-size: 18px
        font-weight: 300
    &__ans__appear
        animation: ans-appear 0.4s ease-in
        display: block
        opacity: 1

    &__info__link
        text-decoration: underline
        color: $txt-lightblue
        cursor: pointer
        font-weight: 400

.faq__img__start
    position: absolute
    top: 20%
    left: -50%
    height: 300px
    z-index: -1
    opacity: 0.3

.faq__img__end
    position: absolute
    bottom: -500px
    right: 0
    height: 300px
    z-index: -1
    opacity: 0.4

@media only screen and (min-width: 728px)
    .faq__content
        width: 90%
@media only screen and (min-width: 1024px)
    .faq
        &__content
            width: 65%
        &__menu
            justify-content: center
            flex-direction: row
        &__count
            font-size: $Size-XL
        &__question
            font-size: $Size-XL
        &__ans
            font-size: 22px

    .faq__img__start
        position: absolute
        top: 10%
        left: -10%
        height: 443px
        z-index: -1
        opacity: 0.4

    .faq__img__end
        position: absolute
        top: 30%
        right: 0
        height: 443px
        z-index: -1
        opacity: 0.6
