
.CuadroTurnos
    width: 100%
    padding: 15px

.tutorial
    width: 100%
    height: 100%
    max-height: 350px
    display: flex
    flex-direction: column
    margin: 20px 0px

    &__title
        margin-bottom: 20px
        font-family: $font-Cairo
        font-size: $Size-L
        font-weight: 600
        text-align: center
        line-height: 25px

    &__video
        width: 100%
        height: 350px
        object-fit: cover
        box-shadow: $box-shadow

.getCuadro
    width: 100%
    display: flex
    flex-wrap: wrap
    justify-content: center
    margin: 30px 0px
    &__title
        font-family: $font-Cairo
        font-size: $Size-M
        margin-bottom: 10px
        font-weight: 600
    &__gif
        width: 90%
        object-fit: cover
        box-shadow: $box-shadow
    &__info
        margin-top: 15px
        display: flex
        flex-direction: column
        align-items: center
        justify-content: space-evenly

        &__text
            padding: 10px
            text-align: justify
            font-size: $Size-S
            font-weight: 400
        &__btn
            margin-top: 10px
            width: 40%
            font-size: $Size-S
            &:active
                background: $bg-greenmint
                box-shadow: inset 2px 2px 6px rgba(0, 0, 0, 0.25)
.reminder
    background: rgba(0, 0, 0, 0.5)
    position: fixed
    z-index: 1000
    top: 0px
    left: 0px
    width: 100%
    height: 100vh
    display: flex
    justify-content: center
    align-items: center
    &__content
        background: linear-gradient(35deg,$bg-greengrass,$txt-white 20%, $txt-lightgrey, $txt-middlelightgrey, $bg-greenmint )
        display: flex
        flex-direction: column
        align-items: center
        padding: 20px
        width: max-content
        position: relative
    &__title
        font-family: $font-Cairo
        font-size: $Size-L
        font-weight: 600
        display: flex
        align-items: center
    &__alertIcon
        color: $A-orange
        font-size: $Size-XL
        margin-right: 8px
    &__iconHappy
        color: $bg-greengrass
        font-size: $Size-L
        
    &__text
        text-align: center
        font-family: $font-Exo
        font-weight: 400
        font-size: $Size-M
        margin: 15px 0px

@media screen and (min-width: 728px)
    .getCuadro
        display: grid
        grid-template-columns: 1fr 1fr
        align-items: center
        justify-content: center
        &__gif
            grid-column: 1
            grid-row: 1/3
            justify-self: center
            margin: 0px
        &__title
            grid-column: 2
            grid-row: 1
            justify-self: center
            text-align: center
            margin: 0px
        &__info
            align-self: flex-start
            grid-column: 2
            grid-row: 2
            margin-top: 0px

@media screen and (min-width: 1024px)

    .CuadroTurnos
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
    .tutorial
        width: 60%
        justify-self: center
        max-height: 380px
    .getCuadro
        // width: 65%
        &__title
            font-size: $Size-L
            justify-self: center
        &__info
            width: 350px
            max-width: 450px
            justify-self: center
            &__text
                font-size: $Size-M

@media screen and (min-width: 1400px)
    .tutorial
        width: 40%
        max-height: 450px
        &__video
            height: 450px
            max-height: 450px
    .getCuadro
        width: 65%
        &__info
            margin-top: -20px
