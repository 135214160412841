// @import "./config"
.menu
    position: sticky
    position: -webkit-sticky
    top: 0
    background: $bg-greenmint
    width: 100%
    height: 100px
    display: flex
    justify-content: space-between
    align-items: center
    padding: 10px
    z-index: 1000
    box-shadow: $box-shadow

.menu-light
    position: sticky
    position: -webkit-sticky
    top: 0
    background: $bg-white
    width: 100%
    height: 100px
    display: flex
    justify-content: space-between
    align-items: center
    padding: 10px
    z-index: 1000
    box-shadow: $box-shadow

.menu__logo
    width: 60px

.menu__logo__icon
    width: 100%
    object-fit: cover
    cursor: pointer

.menu__social__content
    display: flex
    justify-self: center
    align-self: flex-start

.menu__social__link
    &:hover
        color: $bg-greenmint
        .facebook
            color: #1b74e4
        .linkedin
            color: #3777c5
        .twitter
            color: #00acee
        .youtube
            color: #c4302b
        .instagram
            color: $txt-white
            background: #d6249f
            background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)
            box-shadow: 0px 3px 10px rgba(0,0,0,.25)
            border-radius: 10px

.menu__social__icon
    color: $txt-white
    width: 20px
    height: 20px
    margin-right: 10px
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))

.menu__social__iconDark
    color: $bg-greenmint
    width: 20px
    height: 20px
    margin-right: 10px
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))

.menu__icon
    color: $txt-white
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
    font-size: 45px

.menu__iconDark
    color: $bg-greenmint
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
    font-size: 45px

.menu__item
    animation: Modal-swipeLeft $speedBtn ease-in
    position: absolute
    top: 100px
    right: 0%  
    padding: 10px
    background: $txt-white
    width: 100%
    display: grid

.menu__close
    animation: Modal-swipeLeft-close $speedBtn ease-in
    display: none
    right: -100%

.menu__list
    padding: 10px
    &:hover
        background: $txt-middlegrey
    &__link
        font-family: $font-Cairo
        font-size: $Size-M
        font-weight: 600
        text-decoration: none
        color: $txt-black
        display: flex
        align-items: center
        cursor: pointer
    &:nth-child(6)
        grid-row: 1
        

.link__icon
    font-size: $Size-L
    margin-right: 8px
.login__icon
    font-size: $Size-XL
    margin-right: 8px

.login__label
    font-family: $font-Cairo
    font-size: $Size-M

.submenu
    animation: Modal-swipeLeft $speedBtn ease-in
    position: relative
    right: 0%
    padding: 10px
    background: $txt-white
    width: 100%

    &__list
        padding: 10px
        display: flex
        align-items: center
        cursor: pointer
        font-family: $font-Cairo
        font-size: $Size-M
        color: $txt-black
        font-weight: 800

        &:hover
            background: $txt-middlegrey

        &__link
            font-family: $font-Cairo
            font-size: $Size-M
            color: $txt-black
            font-weight: 800
            text-decoration: none
            display: flex
            align-items: center

    &__icon
        background: $bg-greenmint
        padding: 5px
        width: 50px
        height: 50px
        border-radius: 100%
        color: $txt-white
        margin-right: 12px
        box-shadow: 0px 0px 6px 0px $txt-darkblue


@media screen and (max-width: 1024px) and (orientation: landscape)
    .menu__item
        height: 60vh
        overflow-y: auto

@media screen and (min-width: 1024px)
    .menu
        height: 120px
        display: grid
        grid-template-columns: 1fr 1fr
    .menu-light
        height: 120px
        display: grid
        grid-template-columns: 1fr 1fr

    .menu__logo
        width: 80px
        margin-left: 50px
        grid-column: 1
        grid-row: 1/2

    .menu__social__content
        justify-self: flex-end
        grid-row: 1
        grid-column: 2

    .menu__social__icon
        width: 35px
        height: 35px
    .menu__social__iconDark
        width: 35px
        height: 35px

    .menu__right
        justify-self: flex-end
        align-self: flex-end
        grid-row: 1
        grid-column: 2

    .menu__icon,
    .menu__iconDark
        display: none

    .menu__largeScreen
        display: flex
        color: $txt-white
        align-items: flex-end
        font-family: $font-Cairo
        font-size: $Size-S
        font-weight: 700

    .menu__list
        position: relative
        &__link
            width: max-content
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
            color: $txt-white

        &:hover
            background: #2f9b97
            border-radius: 8px
        &__linkDark
            width: max-content
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
            color: $bg-greenmint
            font-weight: 800
            &:hover
                color: $txt-white
                


    .submenu
        animation: Modal-widthLeft 0.2s ease
        position: absolute
        top: 67px
        width: 250px
