
@keyframes appear 
    0% 
        opacity: 0

    100% 
        opacity: 1

.slider 
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    margin-bottom: 1.875rem
    width: 100%
    overflow: hidden

.slider__content
    width: 100%
    padding: 30px

.slider__title
    margin-bottom: 1.5625rem
    align-self: flex-start
    width: 100%
    font-family: $font-Cairo
    font-weight: 400
    font-size: $Size-L
    border-bottom: solid 0.0625rem $txt-darkblue
    color: $txt-darkblue

.right-arrow 
    position: absolute
    right: 0px
    font-size: 40px
    color: $txt-darkblue
    filter: drop-shadow(0.0625rem 0.0625rem 0.125rem $txt-lightgrey)
    z-index: 10
    cursor: pointer
    user-select: none

.left-arrow 
    position: absolute
    left: 0px
    font-size: 40px
    color: $txt-darkblue
    filter: drop-shadow(0.0625rem 0.0625rem 0.125rem $txt-lightgrey)
    z-index: 10
    cursor: pointer
    user-select: none

.slide
    opacity: 0
    width: 100%
    display: none

.slide__active
    animation: appear 0.8s ease-in
    width: 100%
    transition-duration: 0.8s
    display: flex
    flex-direction: column
    align-items: center

.slider__bullets
    margin-top: 0.9375rem
    display: flex

.bullet
    font-size: 1.5625rem
    color: $txt-darkblue

.service__badge
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    text-align: center
    height: 100px
    width: 100px
    padding: 10px
    border: 1px solid $bg-greengrass
    border-right: none
    cursor: pointer

.service__badge:nth-child(3)
    border-right: 1px solid $bg-greengrass


.service__badge__icon
    font-size: $Size-XL
    color: $bg-greenmint

.service__badge__text
    font-size: $Size-S
    font-family: $font-Exo
    font-weight: 600

.badge__active
    background: $bg-greenmint
    color: $txt-white
    & > .service__badge__icon
        color: $txt-white

@media only screen and (min-width: 1024px)

    .slider 
        width: 60%
        align-self: center

    .right-arrow 
        right: 1.875rem
    .left-arrow 
        left: 1.875rem
    
    .service__badge
        width: 150px
        height: 150px